.root {
    padding: 5px 5px 5px 0;
    display: inline-block;
}

.root svg {
    height: 15px;
    width: 15px;
}

.root:hover {
    transform: scale(1.2);
}
