.root {
    width: 100%;
    padding: 0;
    display: block;
}

.text {
    margin: 0;
    padding: 0;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: normal;
    color: var(--black-base);
    margin-bottom: 4px;
}

.required {
    color: var(--secondary-400-base);
    font-weight: 700;
}

.errorWrapper {
    width: 100%;
    background-color: var(--secondary-400-base-error);
    padding: 6px 14px;
    border-radius: 5px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5), 0 16px 24px 0 rgba(48, 49, 51, 0.1);
}

.errorText {
    margin: 0;
    padding: 0;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    line-height: 1.75;
    letter-spacing: normal;
    color: var(--whitebase);
}

.additionalText {
    font-style: italic;
    font-size: 12px;
    font-weight: bold;
}