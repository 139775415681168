.root {
    width: 100%;
    padding: 0;
    display: block;
}

.text {
    margin: 0;
    padding: 0;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: normal;
    color: var(--black-base);
    margin-bottom: 4px;
}

.required {
    color: var(--secondary-400-base);
    font-weight: 700;
}

.errorWrapper {
    width: 100%;
    padding: 6px 14px;
    border-radius: 5px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5), 0 16px 24px 0 rgba(48, 49, 51, 0.1);
}

.errorText {
    margin: 0;
    padding: 0;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    line-height: 1.75;
    letter-spacing: normal;
    color: var(--whitebase);
}

.colorIndicators {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 0.2rem;
}

.colorIndicators {
    height: 5px;
    margin: 2px 0;
}
.colorIndicators > span {
    border-radius: 5px;
}

.colorIndicators > .tooWeek {
    background-color: #cc5151;
}

.colorIndicators > .weak {
    background-color: #ffc966;
}

.colorIndicators > .medium {
    background-color: #ffff4c;
}

.colorIndicators > .strong {
    background-color: #007300;
}

.hintOptions > li.contains {
    color: #007300;
}

.hintOptions > li.error {
    color: #cc5151;
}

.hintOptions {
    list-style: none;
    font-size: 12px;
    padding: 0;
    margin: 0;
}

.message {
    font-size: 12px;
}

.value {
    font-style: italic;
}

.tooWeek1 {
    display: inline-block;
    border-radius: 5px;
    padding: 0 5px;
    background-color: #cc5151;
}

.weak1 {
    display: inline-block;
    border-radius: 5px;
    padding: 0 5px;
    background-color: #ffc966;
}

.medium1 {
    display: inline-block;
    border-radius: 5px;
    padding: 0 5px;
    background-color: #ffff66;
}

.strong1 {
    display: inline-block;
    border-radius: 5px;
    padding: 0 5px;
    background-color: #66b266;
}

/* .errorWrapper {
    width: 100%;
    background-color: var(--secondary-400-base-error);
    padding: 6px 14px;
    border-radius: 5px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5), 0 16px 24px 0 rgba(48, 49, 51, 0.1);
} */
